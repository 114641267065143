import React, { useState, useEffect, useCallback } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Title, Text, NameContainer } from "./Style"
import { H2 } from "../Text"
import Button from "../button"

export default function NameGenerator() {
  const data = useStaticQuery(
    graphql`
      query NameGenerator {
        allContentfulNameGeneratorPage {
          edges {
            node {
              content
              title
              submitLabel
              list {
                adjectives
                names
              }
            }
          }
        }
      }
    `
  )

  const {
    title,
    content,
    submitLabel,
    list,
  } = data.allContentfulNameGeneratorPage.edges[0].node

  const [name, setName] = useState()

  const handleName = useCallback(() => {
    const { adjectives: a, names: n } = list

    // Random numbers are made
    const ra = parseInt(Math.random() * a.length)
    const rn = parseInt(Math.random() * n.length)

    setName(`${a[ra]} ${n[rn]}`)
  }, [list])

  useEffect(() => {
    handleName()
  }, [handleName])

  return (
    <>
      <Title>{title}</Title>
      <Text>{content}</Text>
      <NameContainer>
        <H2>{name}</H2>
      </NameContainer>
      <Button onClick={() => handleName()}>{submitLabel}</Button>
    </>
  )
}
