import styled from "styled-components"
import { Text as Txt } from "../Text"
import { colors, breakpoints } from "../Style"

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 700;
  margin-bottom: 1.4rem;
`

export const Text = styled(Txt)`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
`

export const NameContainer = styled.div`
  padding: 1rem;
  margin: 2rem auto;
  border: 0.2rem solid ${colors.gray["500"]};

  @media ${breakpoints.medium} {
    padding: 2rem;
    margin: 3.2rem auto;
  }
`
