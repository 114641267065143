import React from "react"
import SEO from "../components/seo"
import { Block } from "../components/Block"
import NameGenerator from "../components/name-generator"

const NameGeneratorPage = () => {
  return (
    <>
      <SEO title="Skapa namn" />
      <Block style={{ textAlign: "center" }}>
        <NameGenerator />
      </Block>
    </>
  )
}

export default NameGeneratorPage
